import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Time-series_Cover.jpg';
import metaImage from './assets/meta.jpg';

const TimeSeriesPage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="data-type-time-series"
      helmet={{
        title: 'TileDB - 50x faster and smaller footprint than specialized time-series databases',
        description:
          'TileDB natively supports NumPy datetime objects as array dimensions, allowing you to scale time-series analysis directly on cloud object storage with fast performance and no infrastructure overhead.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="Time-series data"
      title="Achieve massive performance boosts for your time-series analytics"
      heroImage={BackgroundImage}
      icon={dataTypes.timeSeries.icon}
      overview={
        <>
          <p>
            Time-series databases pose great challenges for analyses at extreme scale, due to the velocity and volume of the data being
            ingested from multiple sources. Time is a first-class citizen of these datasets, and most workloads focus on aggregating or
            downsampling across time. It is important for ingestion, storage and processing of time-series data to be performed as
            efficiently and inexpensively as possible.
          </p>
          <p>
            TileDB’s cloud-native, multi-dimensional array format considers time as a natively indexable dimension, and allows you to
            rapidly slice and aggregate massive quantities of time-series data. TileDB is up to 50 times faster and 10 times smaller in
            storage footprint than popular special-purpose time-series databases.
          </p>
          <p>
            TileDB provides built-in time-series indexing, with support for NumPy and R datetime objects at any level of precision. Slice &
            dice massive datasets like historical stock tickers or user activity data with speed and at scale. Combine timestamps with other
            sources, like imagery, to build 3D and 4D time-series cubes for advanced geospatial and change detection use cases. Enjoy secure
            governance for collaboration and a 100% serverless architecture for scale, drastically reducing your TCO.
          </p>
        </>
      }
      relatedItems={[
        dataTypes.dataframes,
        industries.maritime,
        industries.telecom,
        industries.defense,
        industries.earthObservation,
        dataTypes.financialData,
      ]}
    />
  );
};

export default TimeSeriesPage;
